import * as Sentry from '@sentry/nuxt';

Sentry.init({
  dsn: 'https://87aed4cae4f75d6f50a8928abac51a7f@o4507853287391232.ingest.us.sentry.io/4507853292044288',
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0,
  beforeSend(event) {
    // Check if the event URL contains "localhost"
    if (event.request && event.request.url && event.request.url.includes('localhost')) {
      // If the event is from localhost, drop the event
      return null;
    }
    // Otherwise, send the event as usual
    return event;
  },
});
