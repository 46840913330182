export default defineNuxtPlugin({
  name: 'ofetch',
  enforce: 'pre',
  async setup(_nuxtApp) {
    const nuxtApp: any = useNuxtApp();
    const { $cognito }: any = useNuxtApp();
    nuxtApp.$ofetch = async (url: string, options: any = {}) => {
      await $cognito.refreshToken();
      const token: string | null = $cognito.AuthToken?.getJwtToken() || null;
      // Merge the provided options with the Authorization header
      const fetchOptions = {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${token}`,
        },
      };
      return new Promise((resolve, reject) => {
        $fetch(url, fetchOptions).then((response) => {
          return resolve(response);
        }).catch((error) => {
          console.error(error);
          if (error.statusCode === 403) {
            if (location.hostname === 'localhost') {
              alert('Session expired. Please login again.');
              console.log(error);
            }
            else {
              Object.keys(localStorage).forEach((key: string) => {
                if (key.startsWith('CognitoIdentityServiceProvider')) {
                  localStorage.removeItem(key);
                }
              });
              location.href = '/login';
            }
          }
          else {
            return reject(error.response);
          }
        });
      });
    };
  },
  hooks: {
    'app:created'() {},
  },
  env: {
    islands: true,
  },
});
