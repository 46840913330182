import { useUserStore } from '~/store/user';

export const useUnleashStore = defineStore('unleashStore', {
  state: () => ({
    devTool: false,
    showTableauDashboard: false,
    showDndDashboard: false,
  }),
  actions: {
    async getUnleashDetails() {
      const userStore = useUserStore();
      const { $ofetch }: any = useNuxtApp();
      await $ofetch(`/api/unleash`, {
        method: 'GET', params: {
          // org_id: userStore.activeOrgId,
          user_id: userStore.id,
        },
      }).then((res: any) => {
        this.devTool = res.find((item: any = {}) => item.name === 'dev_tool_by_user')?.is_enabled || false;
      }).catch((_error: any) => {
        this.devTool = false;
      });
    },
  },
});
