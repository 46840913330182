import { setCookieValue } from '~/utils/common.utils';
import { organizationList, getSubOrgList, formatCustomFields } from '~/utils/settings/org.util';
import { useUserStore } from '~/store/user';
import { useUtilsStore } from '~/store/utils';

export const useOrgStore = defineStore('orgStore', {
  state: () => ({
    activeOrgId: null,
    org: {},
    orgList: [],
    customList: [],
    subOrgList: [],
    isMasterOrg: false,
    remainingQuota: {},
  }),
  getters: {
    activeCustomFields: (state: any): Array<any> => {
      return state.customList.filter(({ is_user_enabled }: any) => is_user_enabled);
    },
    activeCustomFieldsFlatten: (state: any): Array<any> => {
      const activeCustomFields = state.customList.filter(({ is_user_enabled }: any) => is_user_enabled);
      const customOrgFieldsFlatten: any = [];
      activeCustomFields.forEach((item: any) => {
        customOrgFieldsFlatten.push(item);
        item.children.forEach((child: any) => {
          customOrgFieldsFlatten.push(child);
        });
      });
      return customOrgFieldsFlatten;
    },
    getIsPetaDisabled: (state: any): boolean => !state.org?.is_peta_enabled,
  },
  actions: {
    async fetchActiveOrgData(orgId: any = '') {
      const utilsStore = useUtilsStore();
      try {
        const userStore = useUserStore();
        const runtimeConfig = useRuntimeConfig();

        const { $ofetch }: any = useNuxtApp();
        const { obj }: any = await $ofetch(`${runtimeConfig.public.API_URL}orgs/${orgId || userStore.activeOrgId}`);
        this.org = obj;
        this.activeOrgId = obj.id;
        this.isMasterOrg = obj.is_master;
        setCookieValue('is_master', obj.is_master, 30);
        setCookieValue('active_org_id', obj.id, 30);
        this.orgList = organizationList(obj);
        this.customList = formatCustomFields(obj.custom_fields);
        if (obj.is_master) {
          this.subOrgData(obj.id);
        }
      }
      catch (e) {
        console.error(e);
      }
      finally {
        utilsStore.setGlobalLoader(false);
      }
    },
    async subOrgData(id: string) {
      const { $ofetch }: any = useNuxtApp();
      const subOrgList = await $ofetch(`/api/sub_orgs?id=${id}`, { method: 'GET' });
      this.subOrgList = getSubOrgList(subOrgList);
    },
    async addNewCustomField({ sub_fields, ...formData }: any) {
      const userStore = useUserStore();
      const utilsStore = useUtilsStore();
      const runtimeConfig = useRuntimeConfig();
      utilsStore.setGlobalLoader(true);
      try {
        const body = {
          custom_fields: [...this.customList, formData],
          org_id: userStore.activeOrgId,
        };
        const { $ofetch }: any = useNuxtApp();
        const { obj }: any = await $ofetch(`${runtimeConfig.public.API_URL}orgs/${userStore.activeOrgId}`, {
          method: 'PUT', body,
        });
        this.customList = formatCustomFields(obj.custom_fields);
        if (sub_fields.length) {
          const parentField = obj.custom_fields.find((field: any) => field.label === formData.label);
          let latestCustomFields = obj.custom_fields;
          for (const { id, ...subField } of sub_fields) {
            const subFieldBody = {
              custom_fields: [...latestCustomFields, { ...subField, belongs_to: parentField.id }],
              org_id: userStore.activeOrgId,
            };
            const { obj }: any = await $ofetch(`${runtimeConfig.public.API_URL}orgs/${userStore.activeOrgId}`, {
              method: 'PUT', body: subFieldBody,
            });
            latestCustomFields = obj.custom_fields;
            this.customList = formatCustomFields(obj.custom_fields);
          }
        }
        else {
          this.customList = formatCustomFields(obj.custom_fields);
        }
      }
      catch (e) {
        console.error(e);
      }
      finally {
        utilsStore.setGlobalLoader(false);
      }
    },
    async updateCustomFieldStatus(id: string) {
      const utilsStore = useUtilsStore();
      try {
        const userStore = useUserStore();
        this.customList.forEach((customField: any) => {
          if (customField.id === id) {
            customField.is_user_enabled = !customField.is_user_enabled;
          }
        });
        const runtimeConfig = useRuntimeConfig();
        const body = {
          custom_fields: [...this.customList],
          org_id: userStore.activeOrgId,
        };
        const { $ofetch }: any = useNuxtApp();

        const { obj }: any = await $ofetch(`${runtimeConfig.public.API_URL}orgs/${userStore.activeOrgId}`, {
          method: 'PUT', body,
        });
        this.customList = formatCustomFields(obj.custom_fields);
      }
      catch (e) {
        console.error(e);
      }
      finally {
        utilsStore.setGlobalLoader(false);
      }
    },
    async updateAutoArchiveStatus(days: number) {
      const utilsStore = useUtilsStore();
      try {
        const userStore = useUserStore();
        const runtimeConfig = useRuntimeConfig();
        const { $ofetch }: any = useNuxtApp();
        await $ofetch(`${runtimeConfig.public.API_URL}orgs/${userStore.activeOrgId}`, {
          method: 'PUT', body: { auto_archival_offset_days: days },
        });
      }
      catch (e) {
        console.error(e);
      }
      finally {
        utilsStore.setGlobalLoader(false);
      }
    },
    refreshOrgData(type: string = 'container') {
      const userStore = useUserStore();
      const { $ofetch }: any = useNuxtApp();
      return $ofetch(`/api/org/refresh/${userStore.activeOrgId}/${type}`, { method: 'POST' });
    },
  },
});
