import posthog from 'posthog-js';
import { defineNuxtPlugin } from '#app';

export default defineNuxtPlugin((_nuxtApp) => {
  const runtimeConfig = useRuntimeConfig();
  if (!runtimeConfig.public.POSTHOG_API_KEY || !runtimeConfig.public.POSTHOG_HOST) {
    console.warn('Posthog public key is not set');
    return;
  }

  const posthogClient = posthog.init(runtimeConfig.public.POSTHOG_API_KEY as string, {
    api_host: runtimeConfig.public.POSTHOG_HOST as string,
    person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
    capture_pageview: false, // we add manual pageview capturing below
    loaded: (posthog) => {
      // if (import.meta.env.MODE === 'development') posthog.debug();
      return posthog.identify(localStorage.getItem('LastAuthUser') || '');
    },
    session_recording: {
      maskAllInputs: false,
      maskInputOptions: {
        password: true,
      },
    },
  });

  // Make sure that pageviews are captured with each route change
  const router = useRouter();
  router.afterEach((to) => {
    nextTick(() => {
      posthog.capture('$pageview', {
        current_url: to.fullPath,
      });
    });
  });

  return {
    provide: {
      posthog: () => posthogClient,
    },
  };
});
