<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useUserStore } from '~/store/user';
import AppLoader from '~/components/AppLoader.vue';

const userStore = useUserStore();
const { setLocale } = useI18n();
// setLocale(window?.localStorage?.getItem('i18n_locale') || 'en');
onMounted(async () => {
  setLocale(window?.localStorage?.getItem('i18n_locale') || 'en');
  // await userStore.fetchUser();
});

watch(() => userStore.user, () => {
  // if (userStore?.user?.settings?.language) {
  //   setLocale(userStore?.user?.settings?.language);
  // }
});
</script>

<template>
  <NuxtLoadingIndicator />
  <NuxtLayout>
    <v-app>
      <NuxtPage />
    </v-app>
  </NuxtLayout>
  <NuxtSnackbar />
  <AppLoader />
</template>
