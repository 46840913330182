import { useOrgStore } from '~/store/org';
import { useUtilsStore } from '~/store/utils';
import { useUnleashStore } from '~/store/unleash';
import { setCookieValue } from '~/utils/common.utils';

export const useUserStore = defineStore('userStore', {
  state: (): any => ({
    cognitoSession: null,
    activeOrgId: null,
    id: null,
    name: null,
    email: null,
    onBoardingData: null,
    user: {
      org_scope_list: [],
      settings: {},
    },
    savedViews: [],
  }),
  getters: {
    getFullName: (state: any): string | null => {
      if (
        state.user && state.user.given_name && state.user.given_name.length > 0
        && state.user.family_name && state.user.family_name.length > 0
      ) {
        return `${state.user.given_name} ${state.user.family_name}`;
      }
      else {
        return null;
      }
    },
  },
  actions: {
    setUserSettings(settings: any) {
      this.user.settings = settings;
      if (settings?.saved_views?.version === 'v1') {
        this.savedViews = settings?.saved_views?.data.filter((item: any) => item.org_id === settings.default_org) || [];
      }
    },
    setOnboardingData(data: any) {
      this.onBoardingData = data;
    },
    async fetchUser() {
      if (this.id) {
        return;
      }
      const utilsStore = useUtilsStore();
      const { $cognito, $ofetch }: any = useNuxtApp();
      const runtimeConfig = useRuntimeConfig();

      this.cognitoSession = await $cognito.getSession();
      const orgStore = useOrgStore();
      const unleashStore = useUnleashStore();
      if (this.cognitoSession) {
        localStorage.setItem('LastAuthUser', this.cognitoSession?.payload?.email);
        setCookieValue('LastAuthUser', `${this.cognitoSession?.payload?.email}`, 30);
        try {
          utilsStore.setGlobalLoader(true);
          const { obj: user } = await $ofetch(`${runtimeConfig.public.API_URL}users/${$cognito.User.username}`);

          user.settings = user.settings || {};
          const userAccessToOrg = user.org_scope_list.find((org: any) => org.org_id === user.settings.default_org);
          if (!user.settings?.default_org || !userAccessToOrg) {
            user.settings.default_org = user.org_scope_list[0].org_id;
          }
          this.activeOrgId = user.settings.default_org;
          // sort org_scope_list by org_name
          this.user = user;
          this.user.org_scope_list = user.org_scope_list.sort((a: any, b: any) => a.org_name.localeCompare(b.org_name));
          this.name = user.given_name;
          this.email = user.email;
          this.id = $cognito.User.username;
          this.setUserSettings(user.settings);
          await orgStore.fetchActiveOrgData(this.activeOrgId);
          unleashStore.getUnleashDetails();
        }
        catch (err) {
          console.error(err);
        }
        finally {
          utilsStore.setGlobalLoader(false);
        }
      }
    },
    async updateUserSettings(payload: any) {
      try {
        const { $ofetch }: any = useNuxtApp();
        this.user = await $ofetch('/api/user', {
          method: 'PUT',
          body: payload,
          headers: { Authorization: `Bearer ${this.cognitoSession?.jwtToken}` },
        });
        return this.user;
      }
      catch (err) {
        console.error(err);
      }
    },
    async updateUserPreferences(payload: any) {
      try {
        const { $ofetch }: any = useNuxtApp();
        const runtimeConfig = useRuntimeConfig();

        await $ofetch(`${runtimeConfig.public.API_URL}users/settings`, {
          method: 'PUT', body: payload,
        }).then((res: any) => {
          this.setUserSettings(res);
        }).catch((err: any) => console.log(err));
      }
      catch (err) {
        console.error(err);
      }
    },
    async createSelfSignupUser(payload: any) {
      try {
        return await $fetch(`/api/public_utils/user_self_signup`, {
          method: 'POST', body: payload,
        });
      }
      catch (err) {
        console.error(err);
      }
    },
  },
});
