import * as moment from 'moment';

export default defineNuxtPlugin({
  name: 'moment',
  enforce: 'pre',
  async setup(nuxtApp) {
    nuxtApp.$moment = moment;
  },
  hooks: {},
  env: {
    islands: true,
  },
});
