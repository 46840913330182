export enum LOADER_TYPES {
  DETAILS_PAGE = 'DETAILS_PAGE',
  LIST_PAGE = 'LIST_PAGE',
}
export const CONVERSATION_TYPES = {
  CONTAINER: 'container',
  INVOICE: 'invoice',
  MAWB: 'mawb',
};

export const SERVER_API_CACHING_TIME = 60; // in seconds
export const SERVER_FUNCTION_CACHING_TIME = 60; // in seconds

export const MAPBOX_STYLE = 'mapbox://styles/sagungarg/cld2xcwqb002h01o2xa7ji4wi';
export const MAPBOX_TOKEN = 'pk.eyJ1Ijoic2FndW5nYXJnIiwiYSI6ImNraWg3aTNqYTFzb3UyenBvOXo5Z3E5OTQifQ.3VVzyYJgmHowpPjpyT3xJQ';
