export const organizationList = (org: any): any => {
  const orgData = [orgObjList(org)];
  return orgData;
};

export const formatCustomFields = (list: any) => {
  const fieldTypes = [
    {
      fieldTypeLabel: 'Text',
      fieldTypeValue: 'str',
    },
    {
      fieldTypeLabel: 'Number',
      fieldTypeValue: 'int',
    },
    {
      fieldTypeLabel: 'Weight',
      fieldTypeValue: 'weight',
    },
    {
      fieldTypeLabel: 'Quantity',
      fieldTypeValue: 'quantity',
    },
    {
      fieldTypeLabel: 'Carrier No',
      fieldTypeValue: 'carrier_no',
    },
    {
      fieldTypeLabel: 'Hs Code',
      fieldTypeValue: 'hs_code',
    },
    {
      fieldTypeLabel: 'Un Locode',
      fieldTypeValue: 'un_locode',
    },
    {
      fieldTypeLabel: 'Po Number',
      fieldTypeValue: 'po_number',
    },
  ];
  const formattedList = list.map((listItem: any) => {
    let customList = '';
    listItem['fieldTypeLabel'] = fieldTypes.find(item =>
      item.fieldTypeValue == listItem.type_key)?.fieldTypeLabel || '-';
    if (listItem.children.length > 0) {
      listItem.children.forEach((field: any) => {
        customList += `${field.label}, `;
      });
      listItem['sub_fields'] = customList.slice(0, -2);
    }
    else {
      listItem['sub_fields'] = '-';
    }
    return listItem;
  });
  return formattedList.sort((a: any, b: any) => a.label.localeCompare(b.label));
};

export const getSubOrgList = (subList: any): any => {
  return subList.map(({ org }: any) => {
    return {
      name: org.name || '-',
      id: org.id || '-',
      quota: org.quota || '-',
      api_key: org.api_key || '-',
      no_of_users: getNonPortcastUserCount(org),
      auto_archival_offset_days: org.auto_archival_offset_days,
    };
  });
};

const orgObjList = (listItem: any) => {
  return {
    name: listItem.name || '-',
    id: listItem.id || '-',
    quota: listItem.quota || '-',
    api_key: listItem.api_key || '-',
    no_of_users: getNonPortcastUserCount(listItem),
    auto_archival_offset_days: listItem.auto_archival_offset_days,
  };
};
const getNonPortcastUserCount = (org: any): any => {
  return org.user_list.filter((user: any) => !user.email.includes('@portcast.io')).length;
};
