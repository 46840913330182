import type {
  CognitoIdToken,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import {
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUser,
} from 'amazon-cognito-identity-js';
import {
  publicIpv4,
} from 'public-ip';

const LAMBDA_PRE_AUTHENTICATION_EXCEPTION = 'UserLambdaValidationException';

class CognitoService {
  private readonly UserPool: CognitoUserPool;
  private readonly User: CognitoUser | null;
  private UserSession: CognitoUserSession | null | undefined;

  public AuthToken: CognitoIdToken | null | undefined;
  public nuxtApp: any;

  constructor(UserPoolId: string, ClientId: string, nuxtApp: any) {
    this.UserPool = new CognitoUserPool({ UserPoolId, ClientId });
    this.User = this.UserPool.getCurrentUser();
    this.nuxtApp = nuxtApp;
  }

  public getSession(): Promise<boolean> {
    return new Promise((resolve: any) => {
      if (this.User) {
        this.User.getSession((error: Error, session: CognitoUserSession | null) => {
          if (error) {
            console.error(error);
            return resolve(false);
          }
          this.UserSession = session;
          this.AuthToken = session?.getIdToken();
          return resolve(this.AuthToken);
        });
      }
      else {
        if (location && location.pathname !== '/login') {
          this.unAuthenticateUser('');
        }
        return resolve(false);
      }
    });
  }

  public async authenticateUser(Username: string, Password: string): Promise<object | Error> {
    let clientIp = '';
    try {
      clientIp = (await publicIpv4());
    }
    catch (e) {
      // do nothing as of this moment and let authentication continue
    }

    return new Promise((resolve: any, reject: any) => {
      const AuthDetails: AuthenticationDetails = new AuthenticationDetails({
        Username,
        Password,
        ClientMetadata: { clientIp },
      });
      const UserData: CognitoUser = new CognitoUser({ Username, Pool: this.UserPool });

      UserData.authenticateUser(AuthDetails, {
        onSuccess: (session: CognitoUserSession) => {
          this.UserSession = session;
          this.AuthToken = session?.getIdToken();
          resolve({ login: true, newPasswordRequired: false });
        },
        onFailure: (error: Error) => {
          if (error.name === LAMBDA_PRE_AUTHENTICATION_EXCEPTION) {
            return reject(new Error('You are not allowed to login from this network'));
          }
          reject(error);
        },
        newPasswordRequired: () => {
          sessionStorage.setItem('temp-password', Password);
          resolve({
            login: false,
            newPasswordRequired: true,
          });
        },
      });
    });
  }

  public completeNewPasswordChallenge(Username: string,
    FirstName: string, LastName: string, NewPassword: string, Password: string = ''): Promise<object | Error> {
    return new Promise((resolve: any, reject: any) => {
      const AuthDetails: AuthenticationDetails = new AuthenticationDetails({
        Username,
        Password: Password,
      });
      const UserData: CognitoUser = new CognitoUser({ Username, Pool: this.UserPool });
      UserData.authenticateUser(AuthDetails, {
        onSuccess: (session: CognitoUserSession) => {
          this.UserSession = session;
          this.AuthToken = session?.getIdToken();
          resolve({ login: true, newPasswordRequired: false });
        },
        onFailure: (error: Error) => reject(error),
        newPasswordRequired: () => {
          UserData.completeNewPasswordChallenge(
            NewPassword,
            { given_name: FirstName, family_name: LastName },
            {
              onSuccess: (session: CognitoUserSession) => {
                this.UserSession = session;
                this.AuthToken = session?.getIdToken();
                resolve({ login: true, newPasswordRequired: false });
              },
              onFailure: (error: Error) => reject(error),
            },
          );
        },
      });
    });
  }

  public unAuthenticateUser(Username: string): Promise<boolean | Error> {
    return new Promise((resolve: any, reject: any) => {
      const UserData: CognitoUser = new CognitoUser({ Username, Pool: this.UserPool });
      try {
        UserData.signOut(() => {
          resolve(true);
        });
      }
      catch (error) {
        console.error(error);
        reject(error);
      }
      finally {
        Object.keys(localStorage).forEach((key: string) => {
          if (key.startsWith('CognitoIdentityServiceProvider')) {
            localStorage.removeItem(key);
          }
        });
        // location.href = '/login';
      }
    });
  }

  public forgotPassword(Username: string): Promise<boolean | Error> {
    return new Promise((resolve: any, reject: any) => {
      const UserData: CognitoUser = new CognitoUser({ Username, Pool: this.UserPool });

      try {
        UserData.forgotPassword({
          onSuccess: () => resolve(true),
          onFailure: (error: Error) => reject(error),
        });
      }
      catch (error) {
        reject(error);
      }
    });
  }

  public confirmPassword(Username: string, newPassword: string, verificationCode: string): Promise<boolean | Error> {
    return new Promise((resolve: any, reject: any) => {
      const UserData: CognitoUser = new CognitoUser({ Username, Pool: this.UserPool });

      try {
        UserData.confirmPassword(verificationCode, newPassword, {
          onFailure: (error: Error) => reject(error),
          onSuccess: () => resolve(true),
        });
      }
      catch (error) {
        reject(error);
      }
    });
  }

  public getAttributes(): Promise<string | Error> {
    return new Promise((resolve: any, reject: any) => {
      try {
        if (this.User) {
          this.User.getSession((error: Error, _session: CognitoUserSession | null) => {
            if (error) {
              reject(error);
            }

            this.User?.getUserAttributes((error: any, attributes: any) => {
              if (error) {
                reject(error);
              }
              resolve(attributes.find((item: any) => item.getName() === 'sub').getValue());
            });
          });
        }
        else {
          reject(new Error('No User Logged In'));
        }
      }
      catch (error) {
        reject(error);
      }
    });
  }

  private isTokenExpired(token: CognitoIdToken): boolean {
    const expiration = token.getExpiration();
    const currentTime = Math.floor(Date.now() / 1000);
    return currentTime >= expiration;
  }

  public refreshToken(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.User && this.UserSession) {
        const idToken = this.UserSession.getIdToken();
        if (!this.isTokenExpired(idToken)) {
          return resolve(true);
        }
        this.User.refreshSession(this.UserSession.getRefreshToken(), (error, session) => {
          if (error) {
            return reject(error);
          }
          this.UserSession = session;
          this.AuthToken = session.getIdToken();
          resolve(true);
        });
      }
      else {
        reject(new Error('No user session available'));
      }
    });
  }
}

export default defineNuxtPlugin({
  name: 'cognito',
  enforce: 'pre',
  async setup(nuxtApp) {
    const cognitoService = new CognitoService(
      'ap-southeast-1_7sQVIKkp2',
      'd4ljuio8pud17iiebgl9j3027',
      nuxtApp,
    );
    await cognitoService.getSession();
    nuxtApp.$cognito = cognitoService;
  },
  hooks: {
    async 'app:created'() {
    },
  },
  env: {
    islands: true,
  },
});
