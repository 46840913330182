import { default as _91id_93OIlvIibJBWMeta } from "/vercel/path0/pages/analytics/[id].vue?macro=true";
import { default as indexPF2q6Y8Js3Meta } from "/vercel/path0/pages/analytics/risk_map/index.vue?macro=true";
import { default as container_logsu3SrbB0CxPMeta } from "/vercel/path0/pages/dev_tools/container_logs.vue?macro=true";
import { default as _91type_93x3Ux3bdTlJMeta } from "/vercel/path0/pages/dev_tools/lookup/[type].vue?macro=true";
import { default as detailscIdLZE1yxeMeta } from "/vercel/path0/pages/embed/tracking/air_visibility/[id]/details.vue?macro=true";
import { default as mapeIwKMLFZ2PMeta } from "/vercel/path0/pages/embed/tracking/air_visibility/[id]/map.vue?macro=true";
import { default as detailsAuf8YAPFAEMeta } from "/vercel/path0/pages/embed/tracking/container/[id]/details.vue?macro=true";
import { default as mapVCh0eLyiQuMeta } from "/vercel/path0/pages/embed/tracking/container/[id]/map.vue?macro=true";
import { default as forgotpasswordO5MIuA8l6CMeta } from "/vercel/path0/pages/forgotpassword.vue?macro=true";
import { default as dispute_analysisIrJV3Q1mhhMeta } from "/vercel/path0/pages/freight_audit/dispute_analysis.vue?macro=true";
import { default as empty_returnrgrB5e8pdoMeta } from "/vercel/path0/pages/freight_audit/empty_return.vue?macro=true";
import { default as _91id_93Jbs3qGfpsrMeta } from "/vercel/path0/pages/freight_audit/invoices/[id].vue?macro=true";
import { default as indexoUVcA7frW1Meta } from "/vercel/path0/pages/freight_audit/invoices/index.vue?macro=true";
import { default as healthcheckuYTdPZdkj7Meta } from "/vercel/path0/pages/healthcheck.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as onboardingewPMQ0QeckMeta } from "/vercel/path0/pages/onboarding.vue?macro=true";
import { default as port_congestionB0XhWbYgL8Meta } from "/vercel/path0/pages/portcast_indices/port_congestion.vue?macro=true";
import { default as _91id_93GcUHc7VkJIMeta } from "/vercel/path0/pages/reports/[id].vue?macro=true";
import { default as indexGmBGSTDz3PMeta } from "/vercel/path0/pages/reports/index.vue?macro=true";
import { default as _91id_93y9P195DrmiMeta } from "/vercel/path0/pages/sailing_schedules/[id].vue?macro=true";
import { default as index8Eum9QmVw9Meta } from "/vercel/path0/pages/sailing_schedules/index.vue?macro=true";
import { default as indexKIRFIU1ID4Meta } from "/vercel/path0/pages/settings/index.vue?macro=true";
import { default as org2BBpdZssIOMeta } from "/vercel/path0/pages/settings/org.vue?macro=true";
import { default as signupY93ILdpGpbMeta } from "/vercel/path0/pages/signup.vue?macro=true";
import { default as indexIza77UocLjMeta } from "/vercel/path0/pages/tracking/air_cargo/index.vue?macro=true";
import { default as _91id_93lLbIRiyapaMeta } from "/vercel/path0/pages/tracking/air_visibility/[id].vue?macro=true";
import { default as indexrSmomJiLBSMeta } from "/vercel/path0/pages/tracking/air_visibility/index.vue?macro=true";
import { default as _91id_93f4ESkNhMBWMeta } from "/vercel/path0/pages/tracking/booking/[id].vue?macro=true";
import { default as indexR6Wf1Ym6HWMeta } from "/vercel/path0/pages/tracking/booking/index.vue?macro=true";
import { default as _91id_93fbd9RNiDBqMeta } from "/vercel/path0/pages/tracking/container/[id].vue?macro=true";
import { default as indexqFiHRgQVE5Meta } from "/vercel/path0/pages/tracking/container/index.vue?macro=true";
import { default as json_preview6VsrCu3o9qMeta } from "/vercel/path0/pages/tracking/container/json_preview.vue?macro=true";
import { default as bl_bulkRzZaqtmhapMeta } from "/vercel/path0/pages/tracking/container/upload/bl_bulk.vue?macro=true";
import { default as bl_manualy1CAOkbgCaMeta } from "/vercel/path0/pages/tracking/container/upload/bl_manual.vue?macro=true";
import { default as container_bulk4d1mfvHZnrMeta } from "/vercel/path0/pages/tracking/container/upload/container_bulk.vue?macro=true";
import { default as container_manualkD1DgLSyoFMeta } from "/vercel/path0/pages/tracking/container/upload/container_manual.vue?macro=true";
import { default as container_review8dM5kA63oWMeta } from "/vercel/path0/pages/tracking/container/upload/container_review.vue?macro=true";
import { default as custom_fileds_reviewzF7IQDFyB8Meta } from "/vercel/path0/pages/tracking/container/upload/custom_fileds_review.vue?macro=true";
import { default as indexRWlLfnfGKwMeta } from "/vercel/path0/pages/tracking/index.vue?macro=true";
import { default as indexREJGmfIFmqMeta } from "/vercel/path0/pages/tracking/vessel/index.vue?macro=true";
import { default as buttons1ze0FCuW1UMeta } from "/vercel/path0/pages/uikit/buttons.vue?macro=true";
import { default as _91id_93YpI7hZqT7eMeta } from "/vercel/path0/pages/v2/container_tracking/[id].vue?macro=true";
import { default as _91id_93Xx4t0BSuYRMeta } from "/vercel/path0/pages/v2/container_tracking/embed/[id].vue?macro=true";
import { default as indexIzAM74TyZ6Meta } from "/vercel/path0/pages/v2/container_tracking/index.vue?macro=true";
import { default as _91id_93LavsNVu38xMeta } from "/vercel/path0/pages/v2/container_tracking/map/[id].vue?macro=true";
export default [
  {
    name: "analytics",
    path: "/analytics/:id()",
    meta: _91id_93OIlvIibJBWMeta || {},
    component: () => import("/vercel/path0/pages/analytics/[id].vue").then(m => m.default || m)
  },
  {
    name: "risk_map",
    path: "/analytics/risk_map",
    meta: indexPF2q6Y8Js3Meta || {},
    component: () => import("/vercel/path0/pages/analytics/risk_map/index.vue").then(m => m.default || m)
  },
  {
    name: "container_logs",
    path: "/dev_tools/container_logs",
    meta: container_logsu3SrbB0CxPMeta || {},
    component: () => import("/vercel/path0/pages/dev_tools/container_logs.vue").then(m => m.default || m)
  },
  {
    name: "lookup",
    path: "/dev_tools/lookup/:type()",
    meta: _91type_93x3Ux3bdTlJMeta || {},
    component: () => import("/vercel/path0/pages/dev_tools/lookup/[type].vue").then(m => m.default || m)
  },
  {
    name: "singleAirVisibilityEmbed",
    path: "/embed/tracking/air_visibility/:id()/details",
    meta: detailscIdLZE1yxeMeta || {},
    component: () => import("/vercel/path0/pages/embed/tracking/air_visibility/[id]/details.vue").then(m => m.default || m)
  },
  {
    name: "singleAirVisibilityMapEmbed",
    path: "/embed/tracking/air_visibility/:id()/map",
    meta: mapeIwKMLFZ2PMeta || {},
    component: () => import("/vercel/path0/pages/embed/tracking/air_visibility/[id]/map.vue").then(m => m.default || m)
  },
  {
    name: "singleContainerTrackingDetailsEmbed",
    path: "/embed/tracking/container/:id()/details",
    meta: detailsAuf8YAPFAEMeta || {},
    component: () => import("/vercel/path0/pages/embed/tracking/container/[id]/details.vue").then(m => m.default || m)
  },
  {
    name: "singleContainerTrackingMapEmbed",
    path: "/embed/tracking/container/:id()/map",
    meta: mapVCh0eLyiQuMeta || {},
    component: () => import("/vercel/path0/pages/embed/tracking/container/[id]/map.vue").then(m => m.default || m)
  },
  {
    name: "forgotpassword",
    path: "/forgotpassword",
    meta: forgotpasswordO5MIuA8l6CMeta || {},
    component: () => import("/vercel/path0/pages/forgotpassword.vue").then(m => m.default || m)
  },
  {
    name: "disputeAnalysis",
    path: "/freight_audit/dispute_analysis",
    meta: dispute_analysisIrJV3Q1mhhMeta || {},
    component: () => import("/vercel/path0/pages/freight_audit/dispute_analysis.vue").then(m => m.default || m)
  },
  {
    name: "emptyReturn",
    path: "/freight_audit/empty_return",
    meta: empty_returnrgrB5e8pdoMeta || {},
    component: () => import("/vercel/path0/pages/freight_audit/empty_return.vue").then(m => m.default || m)
  },
  {
    name: "freightAuditSingleInvoice",
    path: "/freight_audit/invoices/:id()",
    meta: _91id_93Jbs3qGfpsrMeta || {},
    component: () => import("/vercel/path0/pages/freight_audit/invoices/[id].vue").then(m => m.default || m)
  },
  {
    name: "freightAuditList",
    path: "/freight_audit/invoices",
    meta: indexoUVcA7frW1Meta || {},
    component: () => import("/vercel/path0/pages/freight_audit/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: "healthcheck",
    path: "/healthcheck",
    component: () => import("/vercel/path0/pages/healthcheck.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: logint0AWlhQgM0Meta || {},
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: onboardingewPMQ0QeckMeta || {},
    component: () => import("/vercel/path0/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "portCongestion",
    path: "/portcast_indices/port_congestion",
    meta: port_congestionB0XhWbYgL8Meta || {},
    component: () => import("/vercel/path0/pages/portcast_indices/port_congestion.vue").then(m => m.default || m)
  },
  {
    name: "singleReport",
    path: "/reports/:id()",
    meta: _91id_93GcUHc7VkJIMeta || {},
    component: () => import("/vercel/path0/pages/reports/[id].vue").then(m => m.default || m)
  },
  {
    name: "reports",
    path: "/reports",
    meta: indexGmBGSTDz3PMeta || {},
    component: () => import("/vercel/path0/pages/reports/index.vue").then(m => m.default || m)
  },
  {
    name: "sailingSchedulesResults",
    path: "/sailing_schedules/:id()",
    meta: _91id_93y9P195DrmiMeta || {},
    component: () => import("/vercel/path0/pages/sailing_schedules/[id].vue").then(m => m.default || m)
  },
  {
    name: "sailingSchedules",
    path: "/sailing_schedules",
    meta: index8Eum9QmVw9Meta || {},
    component: () => import("/vercel/path0/pages/sailing_schedules/index.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/vercel/path0/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "profilePage",
    path: "/settings/org",
    meta: org2BBpdZssIOMeta || {},
    component: () => import("/vercel/path0/pages/settings/org.vue").then(m => m.default || m)
  },
  {
    name: "Signup",
    path: "/signup",
    meta: signupY93ILdpGpbMeta || {},
    component: () => import("/vercel/path0/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: "airCargoTracking",
    path: "/tracking/air_cargo",
    meta: indexIza77UocLjMeta || {},
    component: () => import("/vercel/path0/pages/tracking/air_cargo/index.vue").then(m => m.default || m)
  },
  {
    name: "singleAirVisibility",
    path: "/tracking/air_visibility/:id()",
    meta: _91id_93lLbIRiyapaMeta || {},
    component: () => import("/vercel/path0/pages/tracking/air_visibility/[id].vue").then(m => m.default || m)
  },
  {
    name: "airVisibility",
    path: "/tracking/air_visibility",
    meta: indexrSmomJiLBSMeta || {},
    component: () => import("/vercel/path0/pages/tracking/air_visibility/index.vue").then(m => m.default || m)
  },
  {
    name: "singleBooking",
    path: "/tracking/booking/:id()",
    meta: _91id_93f4ESkNhMBWMeta || {},
    component: () => import("/vercel/path0/pages/tracking/booking/[id].vue").then(m => m.default || m)
  },
  {
    name: "bookingContainers",
    path: "/tracking/booking",
    meta: indexR6Wf1Ym6HWMeta || {},
    component: () => import("/vercel/path0/pages/tracking/booking/index.vue").then(m => m.default || m)
  },
  {
    name: "singleContainerTracking",
    path: "/tracking/container/:id()",
    meta: _91id_93fbd9RNiDBqMeta || {},
    component: () => import("/vercel/path0/pages/tracking/container/[id].vue").then(m => m.default || m)
  },
  {
    name: "containerTracking",
    path: "/tracking/container",
    meta: indexqFiHRgQVE5Meta || {},
    component: () => import("/vercel/path0/pages/tracking/container/index.vue").then(m => m.default || m)
  },
  {
    name: "jsonPreview",
    path: "/tracking/container/json_preview",
    meta: json_preview6VsrCu3o9qMeta || {},
    component: () => import("/vercel/path0/pages/tracking/container/json_preview.vue").then(m => m.default || m)
  },
  {
    name: "tracking-container-upload-bl_bulk",
    path: "/tracking/container/upload/bl_bulk",
    component: () => import("/vercel/path0/pages/tracking/container/upload/bl_bulk.vue").then(m => m.default || m)
  },
  {
    name: "tracking-container-upload-bl_manual",
    path: "/tracking/container/upload/bl_manual",
    component: () => import("/vercel/path0/pages/tracking/container/upload/bl_manual.vue").then(m => m.default || m)
  },
  {
    name: "tracking-container-upload-container_bulk",
    path: "/tracking/container/upload/container_bulk",
    component: () => import("/vercel/path0/pages/tracking/container/upload/container_bulk.vue").then(m => m.default || m)
  },
  {
    name: "tracking-container-upload-container_manual",
    path: "/tracking/container/upload/container_manual",
    component: () => import("/vercel/path0/pages/tracking/container/upload/container_manual.vue").then(m => m.default || m)
  },
  {
    name: "tracking-container-upload-container_review",
    path: "/tracking/container/upload/container_review",
    component: () => import("/vercel/path0/pages/tracking/container/upload/container_review.vue").then(m => m.default || m)
  },
  {
    name: "tracking-container-upload-custom_fileds_review",
    path: "/tracking/container/upload/custom_fileds_review",
    component: () => import("/vercel/path0/pages/tracking/container/upload/custom_fileds_review.vue").then(m => m.default || m)
  },
  {
    name: "tracking",
    path: "/tracking",
    component: () => import("/vercel/path0/pages/tracking/index.vue").then(m => m.default || m)
  },
  {
    name: "vesselTracking",
    path: "/tracking/vessel",
    meta: indexREJGmfIFmqMeta || {},
    component: () => import("/vercel/path0/pages/tracking/vessel/index.vue").then(m => m.default || m)
  },
  {
    name: "buttons",
    path: "/uikit/buttons",
    meta: buttons1ze0FCuW1UMeta || {},
    component: () => import("/vercel/path0/pages/uikit/buttons.vue").then(m => m.default || m)
  },
  {
    name: "cntrTrackingSingle",
    path: "/v2/container_tracking/:id()",
    meta: _91id_93YpI7hZqT7eMeta || {},
    component: () => import("/vercel/path0/pages/v2/container_tracking/[id].vue").then(m => m.default || m)
  },
  {
    name: "singleContainerTrackingDetailsEmbedV2",
    path: "/v2/container_tracking/embed/:id()",
    meta: _91id_93Xx4t0BSuYRMeta || {},
    component: () => import("/vercel/path0/pages/v2/container_tracking/embed/[id].vue").then(m => m.default || m)
  },
  {
    name: "cntrTracking",
    path: "/v2/container_tracking",
    meta: indexIzAM74TyZ6Meta || {},
    component: () => import("/vercel/path0/pages/v2/container_tracking/index.vue").then(m => m.default || m)
  },
  {
    name: "singleContainerTrackingMapEmbedV2",
    path: "/v2/container_tracking/map/:id()",
    meta: _91id_93LavsNVu38xMeta || {},
    component: () => import("/vercel/path0/pages/v2/container_tracking/map/[id].vue").then(m => m.default || m)
  }
]