import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import cognito_QA2Ozez2Ra from "/vercel/path0/plugins/cognito.ts";
import lodash_Tzg4BPll2N from "/vercel/path0/plugins/lodash.ts";
import moment_NWWiRYbNqj from "/vercel/path0/plugins/moment.ts";
import ofetch_TmnSnS1Swe from "/vercel/path0/plugins/ofetch.ts";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_UYiXMU8ZyN from "/vercel/path0/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import sentry_client_VdOeuw25Mb from "/vercel/path0/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/vercel/path0/.nuxt/sentry-client-config.mjs";
import plugin_YksOOA8Nkt from "/vercel/path0/node_modules/nuxt-snackbar/dist/runtime/plugin.js";
import switch_locale_path_ssr_5csfIgkrBP from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import debug_client_j3mkIyXYPA from "/vercel/path0/plugins/debug.client.ts";
import posthog_client_fUucxKWhZE from "/vercel/path0/plugins/posthog.client.ts";
import vuetify_7h9QAQEssH from "/vercel/path0/plugins/vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  cognito_QA2Ozez2Ra,
  lodash_Tzg4BPll2N,
  moment_NWWiRYbNqj,
  ofetch_TmnSnS1Swe,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_UYiXMU8ZyN,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  plugin_YksOOA8Nkt,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  debug_client_j3mkIyXYPA,
  posthog_client_fUucxKWhZE,
  vuetify_7h9QAQEssH
]