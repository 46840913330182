import '@mdi/font/css/materialdesignicons.css';
import 'floating-vue/dist/style.css';
import 'vuetify/styles';
import 'assets/styles/vuetify_override.scss';
import 'assets/styles/sailing_schedules_styles.scss';
import { createVuetify } from 'vuetify';
import { VDateInput } from 'vuetify/labs/VDateInput';
import { VCalendar } from 'vuetify/labs/VCalendar';

export default defineNuxtPlugin((app) => {
  const vuetify = createVuetify({
    display: {
      thresholds: {
        xs: 0,
        sm: 0,
        md: 0,
        lg: 0,
        xl: 1280,
      },
    },
    components: {
      VDateInput,
      VCalendar,
    },
    theme: {
      themes: {

        light: {
          dark: false,
          colors: {
            primary: '#1C7599',
            secondary: '#49606e',
            background: '#F8F8F8',
          },
          variables: {
            'button-small-height': '34px',
            'font-family': 'Mulish',
          },
        },
      },
    },
  });
  app.vueApp.use(vuetify);
});
