import _ from 'lodash';

export const setCookieValue = (cname: string, cvalue: any, exdays: number) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

export const removeDuplicateObjects = (array: Array<any>, property: any) => {
  const uniqueIds: Array<any> = [];
  const unique: any = array.filter((element) => {
    const isDuplicate = uniqueIds.includes(element[property]);
    if (!isDuplicate) {
      uniqueIds.push(element[property]);
      return true;
    }
    return false;
  });
  return unique;
};

export const sortAListByKey = (list: any = [], key: string = ''): any => {
  return list.sort(function (a: any, b: any) {
    const nameA = a[key]?.toUpperCase(); // ignore upper and lowercase
    const nameB = b[key]?.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });
};

export const lowercaseKeys = (obj: any) => {
  return Object.keys(obj).reduce((accumulator: any, key: any) => {
    accumulator[key.toLowerCase()] = obj[key];
    return accumulator;
  }, {});
};

export const formatCurrency = (value: number, config = {
  style: 'currency',
  currency: 'USD',
  locale: 'en-US',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}) => {
  if (value === null || value === undefined || Number.isNaN(value) || isNaN(value)) {
    return '-';
  }

  // Determine if the value is negative
  const isNegative = value < 0;
  // Use the absolute value for formatting to handle negative values correctly
  const absoluteValue = Math.abs(value);

  const formattedValue = new Intl.NumberFormat(config.locale, {
    style: 'currency',
    currency: config.currency,
    minimumFractionDigits: config.minimumFractionDigits,
    maximumFractionDigits: config.maximumFractionDigits,
  }).format(absoluteValue);

  if (config.currency === 'USD') {
    // Prepend "U$" and add the negative sign if the value is negative
    return `${isNegative ? '-' : ''}U${formattedValue}`;
  }
  // For non-USD currencies, prepend the negative sign if the value is negative
  return `${isNegative ? '-' : ''}${formattedValue}`;
};

export const getFormattedValue = (item: any, col: any): any => {
  const dataKey = col.key;
  const dataValue = _.get(item, dataKey);
  if (col?.type === 'boolean') {
    return dataValue ? 'Yes' : 'No';
  }

  if (!dataValue) {
    return '-';
  }

  if (col.type === 'date') {
    return formatDateMonthYearLocal(dataValue);
  }
  if (col.type === 'list') {
    if (Array.isArray(dataValue)) {
      return dataValue.join(', ');
    }
    return dataValue || '-';
  }

  if (col.type === 'list_string') {
    let listString = dataValue;
    if (listString) {
      try {
        // @eslint-disable-next-line no-useless-escape
        listString = listString.replace(/'/g, '"');
        const parsedArray = JSON.parse(listString);
        if (Array.isArray(parsedArray)) {
          const listArrJoined = parsedArray.join(', ');
          return listArrJoined || '-';
        }
        else {
          return listString || '-';
        }
      }
      catch (e) {
        return listString || '-';
      }
    }
    else {
      return '-';
    }
  }
  if (col.type === 'day') {
    const day = dataValue || '-';
    if (day === '-') {
      return '-';
    }
    return `${day} days`;
  }
  if (col.type === 'currency') {
    const value = _.get(item, dataKey, col.defaultValue || 0);
    return formatCurrency(value, {
      currency: col.currency || 'USD',
      locale: col.locale || 'en-US',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: 'currency',
    });
  }
  return dataValue;
};

export const normalise = (value: string): string => {
  return _.capitalize(_.startCase(value));
};
// export const isEmbedded = location.href.includes("/embed/");

export const joinNonEmptyItems = (data: any): string | null => {
  const isValidArray = data && Array.isArray(data) && data.length > 0 && data.some((item: any) => item);
  if (!isValidArray) return null;
  return data.filter((item: any) => item).join(',');
};

export const validateAndTrimString = (data: any): string | null => {
  const isString = typeof data === 'string';
  if (!isString) return null;
  const dataString = data?.trim();
  return dataString && dataString.length > 0 ? dataString : null;
};

export const filterAndJoinCommaSeparated = (data: any): string | null => {
  const isString = typeof data === 'string';
  if (!isString) return null;
  const validArray = data.split(',').filter((item: any) => item);
  if (!validArray.length) return null;
  return validArray.join(',');
};

export const getJourneyStatusColor = (status: string): string => {
  const statusColors: any = {
    late: '#FB3030',
    arrived: '#2B91BB',
    early: '#FEB701',
    on_time: '#0BAD73',
  };
  return statusColors[status] || statusColors.on_time;
};

export const generateBlUniqId = (item = { bl_no: '', carrier_no: '', cntr_no: '' }): string => {
  return `${String(item.bl_no).trim()}_${String(item.carrier_no).trim()}_${String(item.cntr_no).trim()}`;
};

export const getAuditSubTabExceptionText = (isShippingException: boolean, isContractException: boolean) => {
  if (isShippingException && isContractException) {
    return 'Shipping and contract data exception found. Expected costs calculated based on invoice data';
  }
  else if (isShippingException) {
    return 'Shipping data exception found. Expected costs calculated based on invoice data';
  }
  else {
    return 'Contract data exception found. Expected costs calculated based on invoice data';
  }
};

export const clubArrayDataOnKey = (initialData: any[], key: string, sumKeys: any[]) => {
  const clubbedDataDict: any = {};

  for (const initialDataItem of initialData) {
    if (initialDataItem[key] in clubbedDataDict) {
      const existingDataItem = clubbedDataDict[initialDataItem[key]];
      // sum common entries like for import/export we might wanna sum certain entries
      // over all container no. For freight invoices, we wanna sum over all charge
      // types.
      for (const sumKey of sumKeys) {
        existingDataItem[sumKey] += initialDataItem[sumKey];
      }
    }
    else {
      clubbedDataDict[initialDataItem[key]] = { ...initialDataItem };
    }
  }

  return Object.values(clubbedDataDict);
};

export const isFeatureEnabledForOrg = (feature: string, config: any = {}, isAuthenticated: boolean = true): boolean => {
  let _config = config?.authenticated_user;
  if (!isAuthenticated) {
    _config = config?.unauthenticated_user;
  }
  return _.get(_config, feature, false);
};
export const isValidUUID = (uuid: string) => {
  const regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
  return regex.test(uuid);
};
