<script setup lang="ts">
import { useUtilsStore } from '~/store/utils';
// import SinglePageSkeletonLoader from '~/components/common/SinglePageSkeletonLoader.vue';
// import { LOADER_TYPES } from '~/utils/constants.util';

const utilsStore: any = useUtilsStore();
// const route = useRoute();
</script>

<template>
  <div v-show="utilsStore.globalLoader">
    <!--    <template v-if="route.meta.skeletonLoaderType === LOADER_TYPES.DETAILS_PAGE"> -->
    <!--      <SinglePageSkeletonLoader /> -->
    <!--    </template> -->
    <!--    <template v-else-if="route.meta.skeletonLoaderType === LOADER_TYPES.LIST_PAGE"> -->
    <!--      <SinglePageSkeletonLoader /> -->
    <!--    </template> -->
    <!--    <template v-else> -->
    <div class="text-center app-loader">
      <div>
        <span class="loader" />
        <p>{{ $t('common.loading') }}</p>
      </div>
    </div>
    <!--    </template> -->
  </div>
</template>

<style lang="scss">
.app-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader {
    width: 36px;
    height: 36px;
    border: 4px solid #a8a7a7;
    border-bottom-color: #1C7599;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
