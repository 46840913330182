<script setup lang="ts">
import type { NuxtError } from '#app';

const props: any = defineProps({
  error: Object as () => NuxtError,
});
const route = useRoute();
const handleError = () => clearError({ redirect: '/' });

const errorDetails = computed(() => {
  console.log(props.error);

  if (props.error.statusCode === 404) {
    return {
      actionText: 'Go Back To Homepage',
      headline: 'Whoops, 404',
      title: 'Page not found',
      text: 'The page you were looking for does not exist',
    };
  }
  else if (props.error.statusCode === 500) {
    return {
      actionText: 'Go Back To Homepage',
      headline: 'Whoops, 500',
      title: 'Internal Server Error',
      text: 'An error occurred while processing your request',
    };
  }
  else if (props.error.statusCode === 203) {
    return {
      actionText: 'Go Back To Homepage',
      headline: 'Whoops, 500',
      title: 'Internal Server Error',
      text: 'An error occurred while processing your request',
    };
  }
  else {
    return {
      actionText: 'Go Back To Homepage',
      headline: 'Whoops, ',
      title: 'Internal Server Error',
      text: 'An error occurred while processing your request',
    };
  }
});
const isEmbed = computed(() => {
  return route.path.includes('/embed/');
});
</script>

<template>
  <div :class="`global-app-error-page is-embed-${isEmbed}`">
    <v-empty-state
      :action-text="errorDetails.actionText"
      :headline="errorDetails.headline"
      :title="errorDetails.title"
      :text="errorDetails.text"
      image="https://cdn.vuetifyjs.com/docs/images/components/v-empty-state/connection.svg"
      @click:action="handleError"
    />
  </div>
</template>

<style lang="scss">
.global-app-error-page {
  &.is-embed-true {
    .v-empty-state__actions {
      display: none;
    }
  }
}
</style>
