export default defineNuxtPlugin((_nuxtApp) => {
  const isLocalDebugEnabled = localStorage.getItem('debug') === 'true';
  return {
    provide: {
      debug: {
        log: (...args: any[]) => {
          if (isLocalDebugEnabled) {
            console.log('%cDEBUG => ', 'color: red', ...args);
          }
        },
        isLocalDebugEnabled,
      },
    },
  };
});
