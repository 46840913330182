import * as _ from 'lodash';

export default defineNuxtPlugin({
  name: 'lodash',
  enforce: 'pre',
  async setup(_nuxtApp) {
    // this is the equivalent of a normal functional plugin
  },
  hooks: {
    //  can directly register Nuxt app runtime hooks here
    'app:created'() {
      const nuxtApp = useNuxtApp();
      nuxtApp.$lodash = _;
    },
  },
  env: {
    islands: true,
  },
});
