export const useUtilsStore = defineStore('utilsStore', {
  state: () => ({
    globalLoader: true,
    showSideNav: true,
    isEmbed: false,
  }),
  actions: {
    setGlobalLoader(value: boolean) {
      this.globalLoader = value;
    },
    setShowSideNav(status: boolean) {
      this.showSideNav = status;
    },
    setEmbedStatus(status: boolean) {
      this.isEmbed = status;
    },
  },
});
